import { window } from 'browser-monads';
import gsap from 'gsap/gsap-core';
import * as THREE from 'three'

const resolveConfig = require("tailwindcss/resolveConfig");
const tailwindConfig = require("../../tailwind.config.js");
const fullConfig = resolveConfig(tailwindConfig);

const isPreloaded = () => document.body.classList.contains('preloaded')

const checkIfCurrentPath = (linkPath) => {
  const windowPath = window.location.pathname.replace("/", "")
  return windowPath.toLowerCase() == linkPath.toLowerCase()
}

const getBlackHex = () => {
  return fullConfig.theme.colors.black
}

const getSandHex = () => {
  return fullConfig.theme.colors.sand
}

const clamp = (val, min = 0, max = 1) => Math.max(min, Math.min(max, val))

const getSmallScreen = () => fullConfig.theme.screens.md

const setTheme = (mode) => {
  if (mode === 'light') {
    document.body.classList.remove('theme-dark')
    document.body.classList.add('theme-light')
    gsap.set('body', {
      background: getSandHex()
    })
  } else {
    document.body.classList.remove('theme-light')
    document.body.classList.add('theme-dark')
    gsap.set('body', {
      background: getBlackHex()
    })
  }
}

const map = (value, min1, max1, min2, max2) => min2 + (max2 - min2) * (value - min1) / (max1 - min1)

const getRatio = ({ x: w, y: h }, { width, height }, r = 0) => {
  const m = multiplyMatrixAndPoint(rotateMatrix(THREE.Math.degToRad(r)), [w, h])
  const originalRatio = {
    w: m[0] / width,
    h: m[1] / height,
  }

  const coverRatio = 1 / Math.max(originalRatio.w, originalRatio.h)

  return new THREE.Vector2(
    originalRatio.w * coverRatio,
    originalRatio.h * coverRatio,
  )
}


const rotateMatrix = (a) => [Math.cos(a), -Math.sin(a), Math.sin(a), Math.cos(a)]

const multiplyMatrixAndPoint = (matrix, point) => {
  const c0r0 = matrix[0]
  const c1r0 = matrix[1]
  const c0r1 = matrix[2]
  const c1r1 = matrix[3]
  const x = point[0]
  const y = point[1]
  return [Math.abs(x * c0r0 + y * c0r1), Math.abs(x * c1r0 + y * c1r1)]
}

const wrap = (el, wrapper) => {
  el.parentNode.insertBefore(wrapper, el)
  wrapper.appendChild(el)
}


const unwrap = (content) => {
  for (let i = 0; i < content.length; i++) {
    const el = content[i]
    const parent = el.parentNode

    if (parent.parentNode) parent.outerHTML = el.innerHTML
  }
}

const ev = (eventName, data, once = false) => {
  const e = new CustomEvent(eventName, { detail: data }, { once })
  document.dispatchEvent(e)
}

const enteredSite = () => {
  return document.body.classList.contains('has-entered')
}

const hideBody = () => {
  gsap.set('body', {
    opacity: 0
  })
}

const showBody = () => {
  gsap.set('body', {
    opacity: 1
  })
  gsap.set('#scrollarea', {
    opacity: 1
  })
}

const lockScroll = () => {
  document.body.style.overflowY = 'hidden'
}

const unlockScroll = () => {
  document.body.style.overflowY = 'scroll'
}

export default {
  isPreloaded,
  checkIfCurrentPath,
  getBlackHex,
  getSandHex,
  clamp,
  getSmallScreen,
  setTheme,
  map,
  getRatio,
  wrap,
  unwrap,
  ev,
  enteredSite,
  hideBody,
  showBody,
  lockScroll,
  unlockScroll
}