import * as React from "react"
import { COMPONENT_DELAY_MS } from "../helpers/constants"
import CompassionLogo from "./ui/compassion-logo"
import transitions from "../helpers/transitions"

const Nav = ({ inverse }) => {
  const navRef = React.useRef()

  React.useEffect(() => {
    setTimeout(() => {}, COMPONENT_DELAY_MS)
  }, [])

  return (
    <nav ref={navRef} className={`nav ${inverse ? "nav--inverse" : ""}`}>
      <a
        className="link-external"
        href="http://compassion.com.au"
        target="_blank"
        rel="noopener noreferrer"
      >
        Back to Compassion
      </a>
      <a
        href="/"
        onClick={e => {
          transitions.goHome(e)
        }}
      >
        <CompassionLogo cssClass="nav-logo" isLight={true} />
      </a>
      <div className="items-center hidden lg:flex text-sm">
        <div>Restore Hope Appeal</div>
        <div className="w-1 h-1 transform rotate-45 bg-sand mx-3"></div>
        <div>
          Site by{" "}
          <a className="link-line-not-visible" href="https://tomorrow-happens.studio" target="_blank">
            Tomorrow Happens
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Nav
