// See https://tailwindcss.com/docs/configuration for details
module.exports = {
  purge: ["./src/**/*.js"],
  // https://github.com/tailwindlabs/tailwindcss-forms
  plugins: [],
  theme: {
    fontFamily: {
      editorial: ["Editorial New", "sans"],
      gotham: ["Gotham", "sans"],
    },
    spacing: {
      "0": "0vh",
      "1": "0.4vh",
      "2": "0.8vh",
      "3": "1.2vh",
      "4": "1.6vh",
      "5": "2vh",
      "6": "2.5vh",
      "7": "2.9vh",
      "8": "3vh",
      "9": "3.75vh",
      "10": "4vh",
      "11": "4.5vh",
      "12": "5vh",
      "14": "5.8vh",
      "16": "6.67vh",
      "20": "8.3vh",
      "24": "10vh",
      "28": "11.6vh",
      "32": "13.3vh",
      "36": "15vh",
      "40": "16.6vh",
      "44": "18.3vh",
      "48": "20vh",
      "52": "21.6vh",
      "56": "23.3vh",
      "60": "0.25vh",
      "64": "26.6vh",
      "72": "30vh",
      "80": "33.3vh",
      "96": "40vh",
    },
    maxWidth: {
      "0": "0",
      none: "none",
      xs: "33.3vh",
      sm: "40vh",
      md: "46vh",
      lg: "53vh",
      xl: "60vh",
      "2xl": "70vh",
      "3xl": "80vh",
      "4xl": "93.3vh",
      "5xl": "100vh",
      "6xl": "120vh",
      "7xl": "133vh",
      full: "25%",
    },
    fontSize: {
      xs: ["1.25vh", "100%"],
      sm: ["1.5vh", "100%"],
      base: ["1.8vh", "130%"],
      md: ["2vh", "130%"],
      lg: ["2.4vh", "130%"],
      xl: ["3.3vh", "110%"],
      "2xl": ["4.3vh", "110%"],
      "3xl": ["6.6vh", "110%"],
      "4xl": ["8vh", "110%"],
      "5xl": ["13vh", "110%"],
      mh1: ["3rem", "100%"],
      mh2: ["1.8rem", "100%"],
      mp: ["1rem", "130%"],
      ms: ["0.6rem", "100%"],
    },
    colors: {
      black: "#000000",
      sand: "#FEF9EE",
      canary: "#E8CF95",
      transparent: "transparent",
      current: "currentColor"
    },
    extend: {
      transitionTimingFunction: {
        loco: "cubic-bezier(.215,.61,.355,1)",
      },
    },
  },
}
