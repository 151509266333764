import { document } from "browser-monads"
import { navigate } from "gatsby-link"
import { gsap } from "gsap/all"
import { Expo } from "gsap/gsap-core"
import { Power4 } from "gsap/gsap-core"
import Scrollbar from 'smooth-scrollbar'
import utils from "./utils"

const delay0 = 0.5
const delay1 = 1.5
const delay2 = 2

const durationShort = 1.2
const durationLong = 2
const ease = "power4.inOut"

const animateInNavAndFooter = () => {
  gsap.to('.nav, .footer', {
    y: 0,
    duration: 1.2,
    ease: "inOut",
    delay: 1
  })
}

const animateInHome = () => {


  utils.showBody()

  gsap.to('.home-title .word', {
    yPercent: 0,
    rotate: '0deg',
    ease: ease,
    stagger: 0.02,
    duration: durationLong,
    delay: delay0,
  })

  animateInCircleButton(true)

  gsap.to(['.first-content p', '.first-content .fcbc', '.scroll-circle', 'canvas'], {
    opacity: 1,
    duration: durationLong,
    ease: Power4.easeInOut,
    delay: delay1,
    stagger: 0.1
  })

  gsap.delayedCall(delay1, () => {
    animateInNavAndFooter()
    utils.ev('lockScroll', { lock: false })
  })
}

const goHome = (e) => {
  e.preventDefault()
  if (utils.checkIfCurrentPath("")) {
    const scrollbar = Scrollbar.get(document.querySelector('#scrollarea'))
    if (scrollbar) {
      scrollbar.scrollTo(0, 0, 1000)
    }
  } else {
    gsap.to('#circle-swipe', {
      top: '-50vh',
      duration: 1.4,
      width: '500vw',
      height: '500vw',
      ease: Power4.easeInOut,
      onComplete: () => {
        utils.setTheme('dark')
        gsap.set('body', {
          backgroundColor: utils.getBlackHex()
        })
        utils.hideBody()
        navigate('/')
      }
    })
    if (document.querySelector('.disaster-container')) {
      gsap.to('.disaster-container', {
        y: -90,
        opacity: 0,
        duration: 1.4,
        delay: 0,
        ease: Expo.easeInOut
      })
    }
  }
}

const animateInCircleButton = (withDelay) => {
  gsap.to('.circle-donate', {
    scale: 1,
    ease: "elastic.out(1, 0.3)",
    duration: durationShort,
    delay: withDelay ? delay2 : 0
  })
}


const animateOutCircleButton = (withDelay) => {
  gsap.to('.circle-donate', {
    scale: 0,
    ease: "elastic.out(1, 0.3)",
    duration: durationShort,
    delay: withDelay ? delay2 : 0
  })
}

const genericPageTransition = (callback) => {
  gsap.to('.footer', {
    duration: 1,
    yPercent: 100,
    ease: "power2.inOut",
    force3D: true
  })
  gsap.to('.nav', {
    duration: 1,
    yPercent: -100,
    ease: "power2.inOut",
    force3D: true
  })

  gsap.to('.circle-donate-container', {
    opacity: 0,
    ease: "power2.inOut",
    duration: 0.3
  })

  gsap.to('#scrollarea, #scene, #curtains', {
    opacity: 0,
    duration: 1,
    ease: "power2.inOut",
    onComplete: () => {
      if (callback) {
        gsap.to('#scene', {
          duration: 0.3,
          opacity: 0,
          ease: "power1.inOut",
        })
        gsap.to('body', {
          duration: 0.3,
          backgroundColor: utils.getSandHex(),
          duration: 0.3,
          ease: "power1.inOut",
          onComplete: () => {
            if (callback) {
              callback()
            }
          }
        })
      }
    }
  })
}

export default {
  animateInNavAndFooter,
  animateInHome,
  goHome,
  genericPageTransition,
  animateInCircleButton,
  animateOutCircleButton
}
