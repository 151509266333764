/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
// import "../styles/scroll.css"

const Layout = ({ children, inverse }) => {

  React.useEffect(() => {
  }, [])


  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div id="curtains"></div>
      <div id="noise"><div className="noise-inner"></div></div>
      <div id="circle-swipe">
        <svg version="1.1" viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
          <circle cx="250" cy="250" r="200" />
        </svg>
      </div>
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
